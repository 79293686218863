import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import user from "./assets/images/user.png";
import { navigateTo } from "gatsby";
export default function Editor({ tagline, content }) {
  var settings = {
    dots: false,

    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onClickDetail = (x) => {
    navigateTo(`fertile-edge-magazine/${x.slug}`);
  };

  return (
    <div className="Editors">
      <div className="content">
        <h2>{tagline}</h2>

        <Slider {...settings}>
          {content.map((x) => {
            return (
              <div className="box">
                <img src={x.media} alt="blog cover image" />
                <div className="details">
                  <h3>{x.category}</h3>
                  <p onClick={() => onClickDetail(x)}>{x.title}</p>
                  <div className="user">
                    <h5>{`By ${x.user.name}`}</h5>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
