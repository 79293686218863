import React from "react";
import "./assets/css/style.scss";
import { useStaticQuery, graphql } from "gatsby";
import Tabsheader from "./tabsHeader.js";
export default function Fertile() {
  const data = useStaticQuery(graphql`
    {
      allCosmicjsBlogs {
        edges {
          node {
            id
            slug
            content
            bucket
            internal {
              content
            }
          }
        }
      }
    }
  `);

  let blogs = [];

  // const cosmicData = JSON.parse(
  //   data.allCosmicjsBlogs.edges[0].node.internal.content
  // );
  // console.log("COSMIC", cosmicData);
  const cosmicData = data.allCosmicjsBlogs.edges;
  console.log(cosmicData);
  cosmicData.map((data) => {
    const content = JSON.parse(data.node.internal.content);

    let blogObj = {};
    blogObj.title = content.metadata.blog_title;
    blogObj.category = content.metadata.blog_category;
    blogObj.mediatype = "image";
    blogObj.media = content.metadata.blog_image_tumbnail.imgix_url;
    blogObj.postedDate = content.metadata.posted_date;
    blogObj.user = {
      name: content.metadata.author_details.bog_author_name,
      img: content.metadata.author_details.blog_author_image.imgix_url,
    };
    blogObj.detailViewImage = content.metadata.detail_vew_image.imgix_url;
    blogObj.details = content.metadata.detail_description;
    blogObj.slug = content.slug;
    blogs.push(blogObj);
  });
  console.log("BLOGS DATA", blogs);

  const getFilteredPosts = (category) => {
    const filteredPosts = blogs.filter((blog) => blog.category === category);
    // if (filteredPosts.length > 2) return filteredPosts.slice(0, 3);

    return filteredPosts;
  };

  let editorPicks = [];
  blogs.map((blog) => {
    if (
      blog.title === "Humans of Infertility: Dr. Temeka Zore" ||
      blog.title === "Humans of Infertility: Sarah Shockley" ||
      blog.title === "Humans of Infertility: Dr. Raquel Hammonds" ||
      blog.title === "Humans of Infertility: Dr. Mark Trolice" ||
      blog.title === "Humans of Infertility: Monica Caron" ||
      blog.title === "Infertility, what I wish I had known." ||
      blog.title === "From Confusion to Confidence"
    ) {
      editorPicks.push(blog);
    }
  });

  console.log("EDITOR PICK", editorPicks);

  return (
    <div className="Fertileedge">
      <div className="header">
        <h2>
          <span>FERTILITY EDGE</span> MAGAZINE
        </h2>
      </div>
      <Tabsheader
        content_tab_feature={blogs ? getFilteredPosts("Featured") : []}
        content_tab_stories={blogs ? getFilteredPosts("Inspiring Stories") : []}
        content_tab_psychosocial={
          blogs ? getFilteredPosts("Psychosocial") : []
        }
        content_tab_advocacy={blogs ? getFilteredPosts("Advocacy") : []}
        content_tab_spotlight={blogs ? getFilteredPosts("Spotlight") : []}
        content_tab_science={blogs ? getFilteredPosts("Science") : []}
        tagline={"Editors Picks"}
        content={editorPicks}
        reads={blogs ? blogs : []}
      />
    </div>
  );
}
