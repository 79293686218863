import React, { useState } from "react";
import { Tab, Tabs, Col, Row } from "react-bootstrap";
import Tabcontent from "./tabcontent.js";
import Reads from "./reads.js";

function Tabsheaders({
  content_tab_feature = [],
  content_tab_stories = [],
  content_tab_psychosocial = [],
  content_tab_advocacy = [],
  content_tab_spotlight = [],
  content_tab_science = [],
  tagline,
  content,
  reads,
}) {
  const [key, setKey] = useState("Featured");
  return (
    <>
      <div className="tabsheader">
        <div className="content">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="Featured" title="Featured">
              <h3>Featured Works</h3>
              <Row>
                {content_tab_feature.map((x, count) => {
                  return (
                    <>
                      {count < 3 && (
                        <Col xs={12} sm={4}>
                          <Tabcontent
                            media={x.media}
                            cat={x.category}
                            info={x.title}
                            x={x}
                          />
                        </Col>
                      )}
                    </>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="Stories" title="Inspiring Stories">
              <h3>Inspiring Stories</h3>
              <Row>
                {content_tab_stories.map((x) => {
                  return (
                    <Col xs={12} sm={4}>
                      <Tabcontent
                        media={x.media}
                        cat={x.category}
                        info={x.title}
                        x={x}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="Psychosocial" title="Psychosocial">
              <h3>Psychosocial</h3>
              <Row>
                {content_tab_psychosocial.map((x) => {
                  return (
                    <Col xs={12} sm={4}>
                      <Tabcontent
                        media={x.media}
                        cat={x.category}
                        info={x.title}
                        x={x}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="Advocacy" title="Advocacy">
              <h3>Advocacy</h3>
              <Row>
                {content_tab_advocacy.map((x) => {
                  return (
                    <Col xs={12} sm={4}>
                      <Tabcontent
                        media={x.media}
                        cat={x.category}
                        info={x.title}
                        x={x}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="Spotlight" title="Spotlight">
              <h3>Spotlight</h3>
              <Row>
                {content_tab_spotlight.map((x) => {
                  return (
                    <Col xs={12} sm={4}>
                      <Tabcontent
                        media={x.media}
                        cat={x.category}
                        info={x.title}
                        x={x}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Tab>
            <Tab eventKey="Science" title="Science">
              <h3>Science</h3>
              <Row>
                {content_tab_science.map((x) => {
                  return (
                    <Col xs={12} sm={4}>
                      <Tabcontent
                        media={x.media}
                        cat={x.category}
                        info={x.title}
                        x={x}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Tab>
          </Tabs>
        </div>
      </div>
      {key === "Featured" && (
        <Reads tagline={tagline} content={content} reads={reads} />
      )}
    </>
  );
}
export default Tabsheaders;
